  <template>
  <div>
    <!-- <b-card >
      <h4 class="font-weight-bolder mb-2"> 
        Students
      </h4>
      <div class="d-flex">
        <div :class="$store.state.appConfig.layout.skin === 'dark' ? 'border-dark p-2 rounded-lg d-flex shadow-sm': 'border p-2 rounded-lg d-flex shadow-sm'">
          <div class="bg-success text-white p-1 border rounded-circle">
            <feather-icon
              :tooltip="'none'"
              icon="StarIcon"
              size="22"
              v-b-tooltip.hover.top="'show'"
            />
          </div>
          <div class="ml-1">
            <p class="m-0">Students performing well</p>
            <h3 class="font-weight-bolder">200</h3>
          </div>
        </div>
        <div :class="$store.state.appConfig.layout.skin === 'dark' ? 'border-dark p-2 rounded-lg d-flex shadow-sm ml-2': 'border p-2 rounded-lg d-flex shadow-sm ml-2'">
          <div class="bg-danger text-white p-1 border rounded-circle">
            <feather-icon
              :tooltip="'none'"
              icon="AlertCircleIcon"
              size="22"
              v-b-tooltip.hover.top="'show'"
            />
          </div>
          <div class="ml-1">
            <p class="m-0">Students who need help</p>
            <h3 class="font-weight-bolder">50</h3>
          </div>
        </div>
      </div>
    </b-card> -->
    <center v-if="load">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </center>
    <b-card class="w-100 d-flex rounded-lg" v-else>
      <div>
        <b-row class="mb-2">
          <b-col md="4" class="d-flex align-items-center">
            <h4 class="font-weight-bolder">Students</h4>
          </b-col>
          <b-col md="4">
          </b-col>
          <b-col md="4" class="position-relative">
            <b-form-input 
              v-model="filter.keyword"
              placeholder="Search name or email"
              class="pl-3 rounded-lg"
            />
            <i class="fas fa-search position-absolute" style="top: 13px; left: 30px; color: #aaa;"></i>
          </b-col>
        </b-row>
      </div>
      
      <b-table 
        :items="items.data" 
        :fields="fields" 
        striped 
        responsive
      >
        <template #cell(fullName)="data" >
          <div v-if="data.item.fullName !=null" > 
            <b-avatar v-if="data.item.avatar" :src="data.item.avatar"  style=" background: linear-gradient(0deg, rgba(105,34,149,1) 0%, rgba(232,65,133,1) 100%);"/>
            <span class="ml-1"> {{data.item.fullName}} </span>
          </div>
        </template>
        <template #cell(avatar)="data" >
          <div v-if="data.item.avatar != null"></div>
        </template>
        <template #cell(curriculum)="data" >
          <div class="d-flex" v-if="data.item.curriculum != null ">
            <vue-apex-charts
              type="radialBar"
              height="50"
              width="50"
              :options="getChartOptions(data.item.curriculum)"
              :series="[data.item.curriculum]"
            />
            <span class="font-weight-bold text-body-heading mr-25 mt-25">{{ roundNumber(data.item.curriculum) }}%</span>
          </div>
        </template>
        <template #cell(Actions)="data">
          <div class="d-flex justify-content-start align-items-center">
            <b-link
              :tooltip="'none'"
              class="alert-link cutom-alert-link mr-1"
              :to="{ name: 'student_analytics', params: { id: data.item.id } }"
            >
              <feather-icon
                :tooltip="'none'"
                icon="ChevronRightIcon"
                size="22"
                v-b-tooltip.hover.top="'show'"
              />
            </b-link>
          </div>
        </template>
      </b-table>
      <b-row>
        <b-col md="12" class="d-flex justify-content-center">
          <b-pagination
            v-model="items.current_page"
            :total-rows="items.total"
            :per-page="items.per_page"
            @change="onPageChange"
          ></b-pagination>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-0 mt-md-2 rounded-pill"
            @click="exportStudents()"
          >
            <feather-icon
              icon="ExternalLinkIcon"
              class="mr-25"
            />
            <span >Export</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import VueApexCharts from 'vue-apexcharts';
import vSelect from "vue-select";
import {
  BTable,
  BButton,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BBadge,
  BCardText,
  BAvatar,
  VBTooltip,
  BLink,
  BSpinner,
  BPagination,
  BFormInput
} from "bootstrap-vue";

export default {
  name: "topics",
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BCardText,
    BLink,
    BSpinner,
    BPagination,
    ToastificationContent,
    vSelect,
    VueApexCharts,
    BFormInput
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  computed: {
    ...mapGetters({
      items: "teachers/items",
      load: "teachers/load",
    }),
  },
  data() {
    return {
      filter: {
        course_id: null,
        module_id: null,
      },
      fields: [
        { key: "fullName", label: "Full Name" },
        { key: "Points earned", label: "Points earned" },
        { key: "curriculum", label: "Curriculum" },
        { key: "Core competencies", label: "Core competencies" },
        "Actions",
      ],
    };
  },
  methods: {

    init() {
      this.$store
        .dispatch("teachers/students", { query: { page: 1 } })
        .then((_) => {
        });
    },
    onPageChange(page) {
      this.$store
        .dispatch("teachers/students", { query: { page: page } })
        .then((_) => {});
    },
    getChartOptions(percentage) {
      return {
        chart: {
          offsetY: -10,
          sparkline: { enabled: true }
        },
        plotOptions: {
          radialBar: {
            hollow: { size: '20%' },
            dataLabels: {
              showOn: 'always',
              name: { show: false },
              value: { show: false }
            }
          }
        },
        colors: ['#20E647'],
        labels: ['Curriculum']
      };
    },
    exportStudents(){
      this.$store
        .dispatch("teachers/exportStudents")
        .then((res) => {
          this.$swal({
            icon: "success",
            // title: `<h4>Role Updated Successfully</h4>`,
            showConfirmButton: true,
            confirmButtonColor: "#E84185",
            allowOutsideClick: true,
          });
        })
        .catch((error) => {
          if (error.response) {
            this.$swal({
              icon: "error",
              title: `<h4>${error.response.data.error}</h4>`,
              showConfirmButton: true,
              confirmButtonColor: "#E84185",
              allowOutsideClick: true,
            });
          }
        });
      
    }
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss">
</style>